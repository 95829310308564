<template>
    <document-viewer-2 ref="docViewer" @currentPage="(p)=>$emit('currentPage',p)" :default-signature-boxes="defaultSignatureBoxes" :initialDoc="pdfDocument"/>
</template>
<script>

import DocumentViewer2 from "./DocumentViewer2.vue"
import { PDFDocument, rgb} from 'pdf-lib';
import {sign_document} from '@/apis/others/documents'
import fontkit from 'fontkit';
export default {
  props: { 
    initialDoc: { type: String },
    defaultSignatureBoxes:[Array],
   },
  components: {
    DocumentViewer2
  },
  data() {
    return {
     show:false,
     pdfDocument:'',
     pdfBytes:'',
     signaturePlacement:[],
     width:0,
     height:0,
     signatureFonts:[
      {
        name:'Aauto Signature',
        file:'SafiarSignature-6Ya8x.ttf'
      },
      {
        name:'Brittany Signature',
        file:'AmsterdamSignature-4BzOB.ttf'
      },
      {
        name:'Motterdam Signature',
        file:'FlightyRegular-vm7zA.ttf'
      },
      {
        name:'Prestige Signature',
        file:'Theprestigesignature-ywwaM.otf'
      },
      {
        name:'Rich Jullietta Signature',
        file:'TheRichJulliettaDemo-ZVKJZ.ttf'
      },
     ],
     containerDomrect:null
    }
  },
  mounted() {
    this.modifyPdf()
    setTimeout(()=>{
      const container = document.querySelector('.pdf-div');
      this.containerDomrect = container.getBoundingClientRect();
    },1000)
    // console.log('vue',this.initialDoc, pdf)
  },
  methods: {
    async modifyPdf() {
      const url = this.initialDoc
      const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const pdfBytes= await pdfDoc.save()
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
    },
    addSignatureArea(details){

      this.$refs.docViewer.addSignatureBox(details)
    },
    setReceiverSign(data){
      this.signaturePlacement=[data];
      this.$refs.docViewer.currentPage=data.pageNo;
      this.$refs.docViewer.receiverData=data;
    },
    convertHexToRgb(hex){
      console.log(hex);
      const bigint = parseInt(hex.replace('#', ''), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return rgb(r / 255, g / 255, b / 255);
    },
    async signDoc(signature){

      const url = this.initialDoc
      const existingPdfBytes = await fetch(url, {cache: "no-cache"}).then(res => res.arrayBuffer())
      const pdfDoc = await PDFDocument.load(existingPdfBytes)
      const pages = pdfDoc.getPages()
      const currentPage = pages[this.signaturePlacement[0].pageNo-1]
      const pageSize= currentPage.getSize()
      pdfDoc.registerFontkit(fontkit);
      // const imageUrl = signature;
      // const image = await pdfDoc.embedPng(imageUrl);
      const xPosition = (this.signaturePlacement[0].x/ 100) * this.signaturePlacement[0].canvasWidth;
      const yPosition = (this.signaturePlacement[0].y/100) *  this.signaturePlacement[0].canvasHeight;

        const signatureXDiff=this.signaturePlacement[0].canvasWidth/xPosition
        const signatureYDiff=this.signaturePlacement[0].canvasHeight/yPosition
        const signFont=this.signatureFonts.find((s)=>s.name==signature.fontStyle)
        const AautoSignature=await fetch(`/fonts/${signFont.file}`).then(res => res.arrayBuffer());
        const customFont = await pdfDoc.embedFont(AautoSignature);
        // const color=this.convertHexToRgb(signature.color)
        currentPage.drawText(signature.signatureText, {
            x:pageSize.width/signatureXDiff+this.signaturePlacement[0].canvasWidth*.30>pageSize.width?pageSize.width-this.signaturePlacement[0].canvasWidth*.30+10:pageSize.width/signatureXDiff>0?pageSize.width/signatureXDiff-50:pageSize.width/signatureXDiff,
            y:pageSize.height-pageSize.height/signatureYDiff-60,
            width:this.signaturePlacement[0].canvasWidth*.30,
            // height:100
            size:signature.signatureText.length<12?36:signature.signatureText.length<18?26:18,
            font:customFont,
            color:this.convertHexToRgb(signature.color)
          })
      const pdfBytes= await pdfDoc.save()
      this.pdfBytes=pdfBytes;
      let base64String = btoa(
          new Uint8Array(pdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
          ),
      );
      this.pdfDocument='data:application/pdf;base64,' + base64String
      if(base64String){
        this.$emit('pdfSigned')
      }
      
    },
    submitSignature(){
      const blob = new Blob([this.pdfBytes], { type: 'application/pdf' });
      let data= new FormData();
      data.append('file',blob);
      sign_document(this.$route.query.id, data).then((resp)=>{
        this.$bvToast.toast(resp.message, {
              title: 'Signed successfully',
              variant: 'success',
              solid: true,
            })
        setTimeout(()=>this.$router.push('/documents'),[1000])
      })
    },
    getAxis(c,v,w){
      if(c<v){
        return c
      }else{
        return c-v;
      }
    },
    getYAxis(h,c,v){
      if(c<v){
        return h-c-v;
      }else if(c<h/2){
        return h-c-v/2;
      }else{
        return h-c;
      }
    },
  }
}
</script>