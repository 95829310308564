<template>
    <div class="card card-width">
        <b-alert v-if="rejectionReason" show variant="danger" class="px-2 py-1 mb-0 mt-2 mx-2">{{rejectionReason}}</b-alert>
        <div class="row px-3 py-2 justify-content-between align-items-center">
            <div class="col-8">
                <app-heading-2>{{documentTitle}}</app-heading-2>
            </div>
            <b-dropdown
            class="d-block d-lg-none"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Block Level Dropdown Menu"
            right
            variant="none"
            size="sm"
            boundary="viewport"
            no-caret
            >
            <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                />
            </template>
            <b-dropdown-item @click="()=>showComments=true">Comments</b-dropdown-item>
            </b-dropdown>
            <b-sidebar class="d-lg-none "  v-model="sign" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
                <div class="p-2 border-bottom mt-5">
                    <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>sign=false" ></feather-icon>Signing Options</app-heading-2>
                </div>
                <div>
                    <sign-document ref="signatures1" @sign="addSignature" @close="()=>sign=false" @submit="submitSignatures"></sign-document>
                </div>
            </b-sidebar>
            <b-sidebar class="d-lg-none "  v-model="showComments" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
                <div class="p-2 d-flex align-items-center border-bottom mt-5">
                    <app-heading-2 class="mt-1"><feather-icon icon="ChevronLeftIcon" size="22" @click="()=>showComments=false" ></feather-icon>Comments</app-heading-2>
                    <feather-icon class="pull-up ml-auto mr-2 text-primary cursor-pointer" size="20" icon="RefreshCwIcon" @click="$refs.comments1.getComments()" />
                </div>
                <div class="p-2 h-100">
                    <comments-view ref="comments1" class="w-100" /> 
                </div>
            </b-sidebar>
            
            <div class="col-12 col-lg-2 my-1 my-lg-0"  v-if="!disableButtons">
                <app-simple-button :disabled="disableButtons || !loaded " variant="danger" @click="()=>this.$refs.rejectDocument.showModal()">Reject</app-simple-button>
            </div>
            <div class="col-12 col-lg-2 my-1 my-lg-0"  v-if="!disableButtons && !requireSignature">
                <app-simple-button :disabled="disableButtons || !loaded || acceptLoading" variant="primary" @click="acceptDocument()">Accept</app-simple-button>
            </div>
            <div class="col-12 col-lg-2 my-1 my-lg-0" v-if="!sign && !disableButtons && requireSignature">
                <app-simple-button :disabled="(receiverSignArea[0].pageNo!==pageNo) || !loaded " variant="primary" @click="signDoc">Sign Document</app-simple-button>
            </div>
            <div class="col-12 col-lg-2"  >
                <app-simple-button  variant="success" :disabled="documentData!=null && disableDownloadBtn(documentData.finalDocument,documentData.balanceDue, documentData.status)" @click="getDocumnet(documentData._id)">Download</app-simple-button>
            </div>            

        </div>
        <div class="px-2 pb-2">
            <div class="main-card row mx-1">
                <div class="col-lg-9 col-12">
                    <doc-viewer ref="docViewer" :default-signature-boxes="signaturePlacements"  v-if="document"  :initialDoc="document" @currentPage="setCurrentPage" @pdfSigned="()=>$refs.signatures.documentSigned()"/>
                </div>
                <div class="col-3 side-card d-lg-block d-none position-relative">
                    <div v-show="sign">
                        <sign-document ref="signatures" @sign="addSignature" @close="()=>sign=false" @submit="submitSignatures"></sign-document>
                    </div>
                    <div v-show="!sign" class="p-2 h-100">
                        <div class="d-flex">
                            <div class="mr-1">
                                <div class="cursor-pointer tab-heading" :class="[active==0?'text-primary':'']" @click="changeTab(0)">Comments</div>
                            </div>
                            <feather-icon class="pull-up ml-auto text-primary cursor-pointer" icon="RefreshCwIcon" @click="$refs.comments.getComments()" />
                        </div>

                        <comments-view ref="comments" class="w-100" v-if="active==0"/>
                        
                    </div>

                </div>
            </div>
        </div>
        <reject-document ref="rejectDocument"></reject-document>
    </div>
</template>
<script>
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue';
import SignDocument from '@/components/functional-components/other/documents/SignDocument.vue';
import CommentsView from '@/components/functional-components/other/documents/comments/CommentsView.vue';
import Versions from '@/components/functional-components/other/documents/version-history/Versions.vue'
import DocViewer from "@/components/functional-components/other/documents/DocViewer.vue"
import RejectDocument from "@/components/functional-components/other/documents/RejectDocument.vue"
import {BAlert,BDropdown, BDropdownItem, BSidebar} from 'bootstrap-vue'
import {get_document, doc_viewed, accept_document} from '@/apis/others/documents'
import {getUserData} from '@/auth/utils'
export default{
    components:{
        AppHeading2,
        AppSimpleButton,
        SignDocument,
        CommentsView,
        Versions,
        DocViewer,
        RejectDocument,
        BAlert,
        BDropdown, 
        BDropdownItem,
        BSidebar
    },
    data(){
        return{
            sign:false,
            active:0,
            document:'',
            documentTitle:'',
            signaturePlacements:[],
            documentStatus:'',
            rejectionReason:'',
            signedBy:[],
            receiverSignArea:[],
            pageNo:1,
            loaded:false,
            showComments:false,
            requireSignature:false,
            acceptLoading:false,
            documentData:null
        }
    },
    mounted(){
        this.getDocument(this.$route.query.id)
    },
    computed:{
        disableButtons(){
            let disable=false;
            // if(this.documentStatus!='awaiting_signature'){
            //     disable= true
            // }
            const status =['awaiting_signature','pending_approval'].includes(this.documentStatus)
            if(!status){
                disable= true
            }
            let user=getUserData()
            let signedIndex= this.signedBy.findIndex(s=>s.email==user.email)
            if(signedIndex>0){
                disable=true
            }
            return disable
        }  
    },
    methods:{
        getDocument(id){
            get_document(id).then(resp=>{
                this.document = resp.data.fileLink;
                this.documentTitle = resp.data.document.documentName;
                this.documentStatus=resp.data.document.status;
                this.rejectionReason=resp.data.document.rejectionReason;
                this.signedBy=resp.data.document.signedBy
                this.requireSignature=resp.data.document.signaturesRequired;
                this.documentData=resp.data.document
                if(resp.data.document.signaturesRequired){
                    this.getSignatureArea(resp.data.document.signaturePlacements);
                }
            })
        },
        signDoc(){
            this.sign=true;
            this.$refs.signatures.showSignature()
            this.$refs.signatures1.showSignature()
        },
        changeTab(tab){
            this.active=tab
        },
        addSignature(signData){
            this.$refs.docViewer.signDoc(signData)
        },
        getSignatureArea(placements){
            let user= JSON.parse(localStorage.getItem("user"))
            let signaturePlacements = placements.filter(placement=>{
                return placement.email==user.email
            })
            this.receiverSignArea=signaturePlacements
            console.log('receiver sign:',this.receiverSignArea)
            setTimeout(()=>this.$refs.docViewer.setReceiverSign(signaturePlacements[0]),[1000])
        },
        submitSignatures(){
            this.$refs.docViewer.submitSignature()
        },
        setCurrentPage(p){
            this.loaded=true
            this.pageNo=p
            // if(!this.requireSignature){
            //     this.docView()
            // }
        },
        docView(){
            doc_viewed(this.$route.query.id).then(()=>console.log('current document viewed:'))
        },
        acceptDocument(){
            this.acceptLoading=true
            accept_document(this.$route.query.id).then(resp=>{
                this.acceptLoading=false
                this.$bvToast.toast(resp.message, {
                        title: 'You have accepted the document',
                        variant: 'success',
                        solid: true,
                    })
                this.$router.push(`/documents`);
            }).catch(e=>{
                this.acceptLoading=false
            })
        },
        disableDownloadBtn(final,balanceDue,status){
            if(final){
                if(balanceDue<=0){
                    if(status=='completed'){
                        return false
                    }else{
                        return true
                    }
                }else{
                    return true
                }
            }else{
                if(status=='completed'){
                    return false
                }else {
                    return true
                }
            }
        },
        getDocumnet(id){
            get_document(id).then(resp=>{
                if(resp.data.document.signaturesRequired){
                    this.downloadSigned(resp.data.fileLink, resp.data.document.documentName)
                }else{
                    this.download(resp.data.fileLink, resp.data.document.documentName)
                }
            })
        },
        downloadSigned(url,name){
            fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = blobUrl;
                link.download = name || "downloaded-file"; 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl); // Clean up
            })
            .catch(console.error);
        },
        download(url,name){
            var link = document.createElement("a");
            link.setAttribute('download',name);
            link.href = url;
            link.target = '_self';
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

    }
}
</script>
<style scoped> 
.main-card{
    border-radius: 8px;
    height: 100%;
    border: 1px solid #EAECF0;
    background-color: #f2f5fa;
}
.side-card{
    background-color: #ffffff;
    border-radius: 0px 8px 8px 0px;
}
.tab-heading{
    font-size: 16px;
    font-weight: 500;
    color: #4B465C;
    margin-bottom: 22px;
}
.tab-heading:hover{
    color: #004bff;
}
@media(max-width:768px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
</style>